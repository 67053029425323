const rootEnv = window.LUCID_SPA_CONFIG;

export const APIURL = rootEnv?.APIURL || 'https://panelistapi.cintbeta.com';
export const FULLSTORY_ORG = rootEnv?.FULLSTORY_ORG || '';
export const SENTRY_DSN = rootEnv?.SENTRY_DSN || '';
export const SENTRY_ORG_NAME = rootEnv?.SENTRY_ORG_NAME || '';
export const DEPLOYMENT_ENV = rootEnv?.DEPLOYMENT_ENV || '';
export const APP_VERSION = rootEnv?.APP_VERSION || '';
export const SPLIT_IO_BROWSER_API_KEY = rootEnv?.SPLIT_IO_BROWSER_API_KEY || '';
export const RECAPTCHA_KEY = rootEnv?.RECAPTCHA_KEY || '';
