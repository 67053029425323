import { SentryDebugger, setupAnalytics } from '@lucidhq/ui-analytics';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import {
  DEPLOYMENT_ENV as deploymentEnv,
  FULLSTORY_ORG as fullStoryOrg,
  APP_VERSION as releaseVersion,
  SENTRY_DSN as sentryDsn,
  SENTRY_ORG_NAME as sentryOrg,
} from './env';
import { useNoRenderRef } from './useNoRenderRef';

const isDev = import.meta.env.DEV;

export function Analytics() {
  const sentryLocation = useLocation();
  const sentryLocationRef = useNoRenderRef(sentryLocation);

  useEffect(() => {
    if (isDev || !sentryLocationRef) return;

    setupAnalytics({
      sentryDsn,
      deploymentEnv,
      releaseVersion,
      fullStoryOrg,
      sentryOrg,
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    });
  }, [sentryLocationRef]);

  if (isDev) {
    return null;
  }

  return (
    <>
      <SentryDebugger />
    </>
  );
}
